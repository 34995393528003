* {
  padding: 0px;
  margin: 0px;
  font-family: "Roboto", sans-serif;
}
@media (min-width: 955px) {
  * {
    border-radius: 2px;
  }
}
img {
  border-radius: 2px;
}

.shadow {
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.05);
}
.shadow2 {
  -webkit-box-shadow: 0 8px 6px -6px black;
  -moz-box-shadow: 0 8px 6px -6px black;
  box-shadow: 0 8px 6px -6px rgba(0, 0, 0, 0.1);
}

.pulse {
  box-shadow: 0 0 0 rgba(106, 49, 137, 0.4);
  animation: pulse 1s infinite;
}
.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(106, 49, 137, 0.4);
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(106, 49, 137, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(106, 49, 137, 0);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(106, 49, 137, 0.4);
    box-shadow: 0 0 0 0 rgba(106, 49, 137, 0.4);
  }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(106, 49, 137, 0);
    box-shadow: 0 0 0 10px rgba(106, 49, 137, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(106, 49, 137, 0);
    box-shadow: 0 0 0 0 rgba(106, 49, 137, 0);
  }
}

.animated-linear {
  -webkit-transition: background-color 270ms linear;
  -ms-transition: background-color 270ms linear;
  transition: background-color 270ms linear;
  transition: all ease-in-out 270ms;
}

.fade-in {
  animation: fadeIn ease 1s;
  -webkit-animation: fadeIn ease 1s;
  -moz-animation: fadeIn ease 1s;
  -o-animation: fadeIn ease 1s;
  -ms-animation: fadeIn ease 1s;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
